export const wandafwerking = [
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Glad pleisterwerk',
    featured: false,
    subtitle: '- Glad pleisterwerk.',
    url: 'glad-pleisterwerk',
  },
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Sierpleister',
    featured: false,
    subtitle: '- Sierpleister.',
    url: 'sierpleister',
  },
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Behang klaar',
    featured: true,
    subtitle: '- Behang klaar',
    url: 'behang-klaar',
  },
];

export const plafondafwerking = [
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Glad pleisterwerk plafond',
    featured: false,
    subtitle: '- Glad pleisterwerk plafond voor een echt thuis gevoel',
    url: 'glad-pleisterwerk',
  },
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Schuurplafond',
    featured: false,
    subtitle: '- Schuurplafond voor een prachtig mooi plafond.',
    url: 'schuurplafond',
  },
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Plafond plint',
    featured: true,
    subtitle:
      '- Plafond plint voor de ultime combinate van afwerking en design.',
    url: 'plafond-plint',
  },
];

export const speciaalStukwerk = [
  {
    img: 'https://generalplumbingsupply.net/images/content/KOHLER_SUITE.jpg',
    title: 'Beton cire',
    featured: true,
    subtitle: '- Krijg een uniek industrieel gevoel met Beton Cire',
    url: 'beton-cire',
  },
];
