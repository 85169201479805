import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import TouchApp from '@material-ui/icons/TouchApp';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: '100%',
    height: '100%',
    transform: 'translateZ(0)',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, ' +
      'rgba(0,0,0,0.4) 70%, rgba(0,0,0,0) 100%)',
    '& div': {
      marginTop: '8px',
      fontSize: '30px',
      fontWeight: 'bold',
      textShadow: '1px 1px 3px #000;',
    },
    '& span': {
      marginTop: '0px',
      fontSize: '20px',
      fontWeight: 'bold',
      textShadow: '1px 1px 3px #000;',
    },
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  icon: {
    color: 'white',
    fontSize: '16px',
  },
  gridListTile: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    background: 'rgba(0, 0, 0, 0.40)',
    transition: 'all 0.2s ease-in',
    textDecoration: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.70)',
    },
    '& p': {
      marginTop: '-80px;',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '40px',
      fontWeight: 'bold',
      color: 'white',
    },
  },
  clickButton: {
    width: '130px;',
    fontSize: '16px;',
    display: 'flex',
    height: '90%',
    margin: '0 auto',
    color: '#fa0000',
  },
  clickButtonContainer: {
    display: 'flex',
    margin: '0 auto',
    height: '100%',
  },
}));

const Maze = ({ className, data, width, path, gridImages }) => {
  const classes = useStyles();
  const getGridListCols = () => {
    if (isWidthUp('xl', width)) {
      return 1;
    }

    if (isWidthUp('lg', width)) {
      return 1;
    }

    if (isWidthUp('md', width)) {
      return 1;
    }

    return 2;
  };

  return (
    <div className={classnames(classes.root, 'mt-10x mb-10x', className)}>
      <GridList cellHeight={400} spacing={1} className={classes.gridList}>
        {data.map((tile, i) => (
          <GridListTile
            key={i}
            cols={tile.featured ? 2 : getGridListCols()}
            rows={1}
            className='grid'
          >
            <AniLink
              to={`${path}/${tile.url}`}
              className={classes.gridListTile}
            >
              <div className={classes.clickButtonContainer}>
                <TouchApp className={classes.clickButton} />
              </div>
              <p>Meer info</p>
            </AniLink>
            <a>
              <Img
                style={{ objectFit: 'cover', height: '100%', zIndex: '-1' }}
                fluid={gridImages[i].childImageSharp.fluid}
                alt={tile.title}
              />
            </a>
            <GridListTileBar
              title={tile.title}
              titlePosition='top'
              actionPosition='left'
              className={classes.titleBar}
              subtitle={
                <span className={classes.subtitle}>{tile.subtitle}</span>
              }
              actionIcon={
                <IconButton
                  aria-label={`info about ${tile.title}`}
                  className={classes.icon}
                ></IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

Maze.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  gridImages: PropTypes.array,
  path: PropTypes.string,
  width: PropTypes.string,
};

export default withWidth()(Maze);
